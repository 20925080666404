.c2c_poceedView{
    height:100vh;
    width:100vw; 
    position:relative;
    background-color: #f3f4f6;
    font-family: c2c-roboto-regular;
    .subContainer{
        position: absolute;
        // top:20%;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        background-color: #f3f4f6;

        .proceedView{
            display: flex;
            justify-content:center;
            width: 100vw;

            .box{
                height: auto;
                padding:15px 15px 30px 15px;
                width: 600px;
                background-color: #ffff;
                border-radius: 6px; 
                box-shadow: 0 5px 15px rgba(0,0,0,.5);

                .heading{
                    display: flex;
                    justify-content:center;

                    .title{
                        color: #1acbb1;
                        font-size: 22px;
                        margin-top: 0px;
                    }

                    .text{
                        color: #7b7b7b;
                        font-size: 17px;
                    }

                    .textItem{
                        color: #7b7b7b;
                        // font-size: 14px;
                        font-size: 17px;
                    }

                    button{
                        color:#fff;
                        background:#1acbb1;
                        cursor: pointer;
                        font-size: 18px!important;
                        font-family: c2c-roboto-regular!important;
                        height: 40px!important;
                        width: 106px!important;
                        padding: 6px 12px!important;
                        border-radius: 6px!important;
                        border: none;
                        font-weight: 600!important;
                    }
                }
            }
        }
    }

    .loader{
        display: flex;
        justify-content:center;
        text-align: center;
        align-items: center;
        height:100vh;
        position: relative;
    }

}
.message {
    animation: slideIn 1s ease-in-out, slideOut 1s ease-in-out 2.5s;
  }
  @keyframes slideIn {
    from {
      right: -100%; 
    }
    to {
      right: 20px; 
    }
  }
  
  @keyframes slideOut {
    from {
      right: 20px; 
    }
    to {
      right: -100%; 
    }
  }

@media (max-width:768px) {
    .c2c_poceedView .subContainer{
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        background-color: #f3f4f6;
    }
    .c2c_poceedView .subContainer .proceedView .box{
        margin-left: 30px;
        margin-right: 30px;
    }
// }
// @media (max-width:500px) {
    .c2c_poceedView .subContainer .proceedView .box{
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 40px;
        width: calc(100% - 60px);
    }
}
@media (max-height:600px) {
    .c2c_poceedView .subContainer{
        top: 30px;
        transform: unset;
        -ms-transform:unset;
    }
    .c2c_poceedView .subContainer .proceedView .box{
        margin-bottom: 40px;
    }
}
