.c2c_thankYou{
    height:100vh;
    width:100vw; 
    position:relative;
    background-color: #f3f4f6;
    font-family: c2c-roboto-regular;
    .subContainer{
        position: absolute;
        // top:25%;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        background-color: #f3f4f6;

        .thankYou{
            display: flex;
            justify-content:center;
            width: 100vw;

            .box{
                    height: auto;
                    padding:15px 15px 80px 15px;
                    width: 600px;
                    background-color: #ffff;
                    border-radius: 6px; 
                    box-shadow: 0 5px 15px rgba(0,0,0,.5);

                .heading{
                        display: flex;
                        justify-content:center;
                        text-align: center;

                    .title{
                           color: #1acbb1;
                           font-size: 22px;
                           padding-top: 7px;
                           font-weight: 600;
                    }

                    .text{
                            padding: 0 15px 0 15px;
                            color: #7b7b7b;
                            font-size: 19px;
                            margin-left: 10px;
                            margin-bottom: 22px;
                            margin-top: 22px;
                            line-height: 30px;
                    }
                }
                
            }
        }
    }

    .loader{
        display: flex;
        justify-content:center;
        text-align: center;
        align-items: center;
        height:100vh;
        position: relative;
    }
    
}
@media (max-width:768px){
    .c2c_thankYou .subContainer{
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .c2c_thankYou .subContainer .thankYou .box{
        margin-left: 30px;
        margin-right: 30px;
    }
// }
// @media (max-width:500px){
    .c2c_thankYou .subContainer .thankYou .box{
        padding-bottom: 50px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .c2c_thankYou .subContainer .thankYou .box .heading .text{
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 17px;
        line-height: 23px;
    }
}
@media (max-height:600px) {
    .c2c_thankYou .subContainer{
        top: 30px;
        transform: unset;
        -ms-transform:unset;
    }
    .c2c_thankYou .subContainer .thankYou .box{
        margin-bottom: 50px;
    }
}