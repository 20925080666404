    .c2c_Otp{
        min-height:100vh;
        width:100vw; 
        position:relative;
        background-color: #f3f4f6;

        .subContainer{
            position: absolute;
            //top:25%;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            background-color: #f3f4f6;

            .otp{
                display: flex;
                justify-content:center;
                width: 100vw;

                .box{
                    height: auto;
                    padding:0px 15px 15px 15px;
                    width: 600px;
                    border-radius: 6px; 
                    background-color: #ffff;
                    box-shadow: 0 5px 15px rgba(0,0,0,.5);

                    .c2c_logo {
                        background-image: url(https://uat-front.click2check.com/images/logoBeta.png);
                        background-size: 100%;
                        height: 111px;
                        width: 100px;
                        background-repeat: no-repeat;
                        background-position: center;
                        display: inline-block;
                    }

                    .heading{
                        display: flex;
                        justify-content:center;

                        .Welcome{
                           color: #e61758;
                           font-size: 22px;
                           padding-top: 7px;
                        }

                        .text{
                            padding: 0 15px 0 15px;
                            color: #7b7b7b;
                            font-size: 17px;
                            margin-left: 10px;
                            margin-bottom: 22px;
                            margin-top: 22px;
                            word-break: normal;
                        }

                       
                        .buttonCancel{
                            color:#fff;
                            background:#7b7b7b;;
                            cursor: pointer;
                            font-size: 18px!important;
                            font-family: c2c-roboto-regular!important;
                            height: 40px!important;
                            width: 106px!important;
                            padding: 6px 12px!important;
                            border-radius: 6px!important;
                            border: none;
                            font-weight: 400!important;
                            
                        }

                        .buttonConfirm{
                            color:#fff;
                            background:#1acbb1;
                            cursor: pointer;
                            font-size: 18px!important;
                            font-family: c2c-roboto-regular!important;
                            height: 40px!important;
                            width: 106px!important;
                            padding: 6px 12px!important;
                            border-radius: 6px!important;
                            border: none;
                            font-weight: 400!important;
                        }
                    }

                    .otpHeading{
                        color: #424242;
                        font-size: 18px;
                        font-family: 'c2c-roboto-medium';
                        font-weight: 400;
                        margin-top: 0px;
                        margin-bottom: 10px;
                        padding: 0 20px 0 20px;
                    }

                    .otpInput{
                        padding: 0 20px 0 20px;
                        .inputStyle{
                        border-radius: 4px;
                        font-size: 20px;
                        width: 100%;
                        height: 40px;
                        border: 1px solid #ccc;
                        
                        }
                        input[type=number]:focus {
                            outline: none;
                        }
                    }

                }
            }
        }

        .loader{
            display: flex;
            justify-content:center;
            text-align: center;
            align-items: center;
            height:100vh;
            // z-index: 100;
            position: relative;
        }
    }
    @media (max-width:768px) {
        .c2c_Otp .subContainer{
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
        }
        .c2c_Otp .subContainer .otp .box{
            margin-left: 30px;
            margin-right: 30px;
        }
    // }
    // @media (max-width:500px) {
        .c2c_Otp .subContainer .otp .box{
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    @media (max-height:600px) {
        .c2c_Otp .subContainer{
            top: 30px;
            transform: unset;
            -ms-transform:unset;
        }
        .c2c_Otp .subContainer .otp{
            margin-bottom: 50px;
        }
    }