
.c2c_creditAssesmentService{

    // height: 60vh;
    height: 63vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    padding:10px;
    color: #7b7b7b!important;


    .heading{
        font-size: 18px;
        margin-bottom:10px;
        margin-top: 10px;
        font-weight: 500 !important;
    }

    .subHeading{
        font-size: 14px;
        margin: 0px;
        line-height: 20px;
    }

}

.footer{
    border-top: 1px solid #7b7b7b;
    background-color: white;
    display: flex;

    .button{
        margin-top: 10px;
        margin-bottom: 10px;
        color:#fff;
        background:#1acbb1;
        cursor: pointer;
        font-size: 18px!important;
        font-family: c2c-roboto-regular!important;
        height: 40px!important;
        width: 106px!important;
        padding: 6px 12px!important;
        border-radius: 6px!important;
        border: none;
        font-weight: 600!important;
    }
}
@media ( max-width : 768px){
    .c2c_creditAssesmentService {
        height: calc( 60vh - 65px);
    }
}
@media ( max-height : 850px){
    .c2c_creditAssesmentService {
        height: calc( 60vh - 30px);
    }
}
@media ( max-height : 800px){
    .c2c_creditAssesmentService {
        height: calc( 60vh - 65px);
    }
}
@media ( max-height : 700px){
    .c2c_creditAssesmentService {
        height: calc( 60vh - 105px);
    }
}
@media ( max-height : 600px){
    .c2c_creditAssesmentService {
        height: calc( 60vh - 135px);
    }
}
@media ( max-height : 500px){
    .c2c_creditAssesmentService {
        height: calc( 60vh - 176px);
    }
}
@media ( max-height : 400px){
    .c2c_creditAssesmentService {
        height: calc( 60vh - 185px);
    }
}
@media ( max-width : 690px){
    @media ( max-height : 900px){
        .c2c_creditAssesmentService {
            height: calc( 60vh - 89px);//102px);
        }
    }
    @media ( max-height : 800px){
        .c2c_creditAssesmentService {
            height: calc( 60vh - 127px);//145px);
        }
    }
    @media ( max-height : 700px){
        .c2c_creditAssesmentService {
            height: calc( 60vh - 168px);//187px);
        }
    }
    @media ( max-height : 600px){
        .c2c_creditAssesmentService {
            height: calc( 60vh - 207px);//228px);
        }
    }
    @media ( max-height : 500px){
        .c2c_creditAssesmentService {
            height: calc( 60vh - 190px);//176px);
        }
    }
    @media ( max-height : 400px){
        .c2c_creditAssesmentService {
            height: calc( 60vh - 182px);//211px);
        }
    }
}