    .c2c_templateView{
        height:100vh;
        width:100vw; 
        position:relative;
        background-color: #f3f4f6;
       
        .subContainer{
            position: absolute;
            background-color: #f3f4f6;
         
            .templateView{
                display: flex;
                justify-content:center;
                width: 100vw;
                height:100vh;

                .box{
                    height:90vh;
                    // margin-top: 30px; 
                    margin-top: 35px; 
                    margin-bottom: 30px; 
                    padding:0px;
                    // width: 60%;
                    width: 63%;
                    background-color: #ffff;
                    box-shadow: 0 5px 15px rgba(0,0,0,.5);
                    

                    .c2c_logo {
                        // background-image: url(https://uat-front.click2check.com/images/logoBeta.png);
                        // background-size: 100%;
                        // height: 111px;
                        // width: 100px;
                        height: 75px;
                        margin-top: 15%;
                        background-repeat: no-repeat;
                        background-position: center;
                        display: inline-block;
                    }

                    .tabs{
                        height:auto;
                        .ant-tabs-nav-wrap {
                            position: relative;
                            display: flex;
                            flex: auto;
                            align-self: stretch;
                            overflow: hidden;
                            white-space: nowrap;
                            transform: translate(0);
                            background: #024975;
                        }

                        .ant-tabs-tab{
                            border-radius: 0px !important;
                            margin-left: 0px !important;
                        }

                       .ant-tabs-top >.ant-tabs-nav {
                            margin: 0 ; 
                        }

                        .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
                            color: #626976;
                            text-shadow: 0 0 0.25px currentcolor;
                            
                        }

                        .ant-tabs-card .ant-tabs-nav .ant-tabs-tab {
                            margin: 0;
                            padding: 8px 40px 8px 20px;
                            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                            color: white;
                            text-shadow: 0 0 0.25px currentcolor;
                            border: 1px solid #024975;
                        }

                        .ant-tabs-card.ant-tabs-top >.ant-tabs-nav .ant-tabs-tab-active{
                            border-top-color: #f0f0f0;
                            border-bottom-color: white;
                            border-left-color: #f0f0f0;
                            // border: 1px solid #f0f0f0;
                        }
                        
                    } 
                    
                }
            }
        }

        .loader{
            display: flex;
            justify-content:center;
            text-align: center;
            align-items: center;
            height:100vh;
            position: relative;
        }

    }

        .ant-modal .ant-modal-body {
            font-size: 14px;
            line-height: 1.5714285714285714;
            word-wrap: break-word;
            padding: 15px;
            color: #7b7b7b!important;
        }

        .ant-modal .ant-modal-content {
            position: relative;
            background-color: #ffffff;
            background-clip: padding-box;
            border: 0;
            border-radius: 0px;
            // box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
            box-shadow: 0 5px 15px rgba(0,0,0,.5);
            pointer-events: auto;
            padding: 0px;
        }

        .ant-modal .ant-modal-footer {
            text-align: end;
            background: transparent;
            border-top: 1px solid #e5e5e5;
            margin: 0px;
            padding: 15px;
        }

       .ant-btn-default {
            color: #fff;
            background:#1acbb1;
            box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
            border-color: #1acbb1;
            border-radius: 4px;
        }

        .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
            color: #fff;
            background:#1acbb1;
            border-color: #1acbb1;
            border-radius: 4px;
        }

        .ant-modal .ant-modal-close {
            position: absolute;
            top: 5px;
            inset-inline-end: 17px;
            z-index: 1010;
            padding: 0;
            color: rgba(0, 0, 0, 0.45);
            font-weight: 600;
            line-height: 1;
            text-decoration: none;
            background: transparent;
            border-radius: 4px;
            width: 22px;
            height: 22px;
            border: 0;
            outline: 0;
            cursor: pointer;
            transition: color 0.2s,background-color 0.2s;
        }
        .ant-tabs-nav{
            font-family: Arial;
        }
        @media (max-width:1250px){
          .c2c_templateView .subContainer .templateView .box{
              width: 70%;
          }
        }
        @media (max-width:950px){
          .c2c_templateView .subContainer .templateView .box{
              width: 85%;
          }
        }
        @media (max-width:786px){
          .c2c_templateView .subContainer .templateView .box{
              width: 100%;
              margin-left: 30px;
              margin-right: 30px;
          }
        }
        @media (max-width:690px){
            .c2c_templateView .subContainer .templateView .box .tabs .ant-tabs-top > .ant-tabs-nav{
                border: 1px solid #f0f0f0;
            }
            :where(.css-dev-only-do-not-override-nnuwmp).ant-tabs >.ant-tabs-nav .ant-tabs-nav-list 
            // :where(.css-dev-only-do-not-override-nnuwmp).ant-tabs >div>.ant-tabs-nav .ant-tabs-nav-list 
            {
                display: grid;
               
            }
            .c2c_templateView .subContainer .templateView .box .tabs .ant-tabs-card .ant-tabs-nav .ant-tabs-tab{
                width: 100%;
                border-right-color: #f0f0f0;
            }
            .c2c_templateView .subContainer .templateView .box .tabs .ant-tabs-nav-wrap{
                display: block;
            }
        }
        @media (max-width:500px){
            .c2c_templateView .subContainer .templateView .box {
                margin: 15px;
                margin-bottom: 30px;
                width: calc(100% - 30px );
            }
        }
    @media ( max-width : 690px){
        @media ( max-height : 500px){
            .ico_set_logoaf{
                max-height: 19px;
                margin-bottom: 5px !important;
            }
            .ico_set_logoaf img{
                width: 19px;
            }
            .c2c_templateView .subContainer .templateView .box .c2c_logo{
                max-width: 100px;
                height: 80px;
            }
            .c2c_templateView .subContainer .templateView .box .tabs{
                margin-top: 0px !important;
            }
        }
    }