.c2c_confirmDetails{

    // height: 60vh;
    height: 63vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding:10px;
    color: #7b7b7b!important;
    
    .heading{
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        border-bottom: 1px solid #e1e3e8;
        font-family: c2c-roboto-regular;
        margin-top: 20px;
    }
    .headingAddress{
        font-size: 14px;
        font-weight: 500;
        border-bottom: 1px solid #e1e3e8;
        font-family: Arial;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        padding: 0 11px;
        height: 40px;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #ccc;
        
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: #313131; 
        background: white;
        cursor: not-allowed;
    }

    .ant-picker .ant-picker-input >input-disabled, .ant-picker .ant-picker-input >input[disabled] {
        color: #313131;
        background-color: white;
        border-color: #ccc;
    }

    .ant-form-item .ant-form-item-control-input {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 40px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
        position: relative;
        user-select: none;
        align-items: center;
        display: flex;
    }

    .input{
        height: 40px;
        color: #313131;
        font-size: 14px;
        padding: 6px 12px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    
    .ant-input:focus{
        border-color: #d9d9d9;
        box-shadow: 0 0 0 0px;
    }
    .ant-input:hover{
        border-color: #d9d9d9;
    }

    
    .RadioButton{

        .ant-radio-wrapper {
            margin-inline-end: 71px;
        }

        .ant-radio-wrapper:hover {
            // color:#1acbb1;
        }

        .ant-radio-wrapper .ant-radio-inner:hover{
            // border-color:#1acbb1 ;
        }

        .ant-radio-wrapper .ant-radio-inner{
            background-color: white;
            border-color: #d9d9d9;
        }

       .ant-radio-wrapper .ant-radio-inner::after {
            width: 24px;
            height: 24px;
            margin-block-start: -12px;
            margin-inline-start: -12px;
        }


        .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
            background-color: #d9d9d9;
        }

        .ant-radio-wrapper .ant-radio-checked::after {
            // border: 1px solid #1acbb1;
            border:none;
        }

   }

   .ant-picker{
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #ccc;
   }

   .ant-picker-focused {
    border-color: #d9d9d9;
    box-shadow: 0 0 0 0px;
   }

   .ant-picker:hover{
    border-color: #d9d9d9;
   }

   .button{
    background-color: #1acbb1;
    color: #fff;
    font-weight: 600;
    border: none;
    height: 37px;
    width: 171px;
    font-size: 14px;
    border-radius: 6px!important;
    cursor: not-allowed;
    font-family: Arial;
   }

}

:where(.css-dev-only-do-not-override-1adbn6x).ant-form-vertical .ant-form-item-label >label{
    color: #7b7b7b;
    font-family: c2c-roboto-regular;
    font-weight: 600;
}
.footer{
    border-top: 1px solid #7b7b7b;
    background-color: white;
    display: flex;

    .button{
        margin-top: 10px;
        margin-bottom: 10px;
        color:#fff;
        background:#1acbb1;
        cursor: pointer;
        font-size: 18px!important;
        font-family: c2c-roboto-regular!important;
        height: 40px!important;
        width: 106px!important;
        padding: 6px 12px!important;
        border-radius: 6px!important;
        border: none;
        font-weight: 400!important;
    }

}
.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: inline-block;
    margin-inline-end: 0px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: "*";
    color: #1acbb1;
    font-size: 20px;
}

.ant-form-item {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    margin-bottom: 0px;
    vertical-align: top;
}
@media ( max-width : 768px){
    .c2c_confirmDetails {
        height: calc( 60vh - 65px);
    }
}
@media ( max-height : 400px){
    .c2c_confirmDetails {
        height: calc( 60vh - 165px);
    }
}
@media ( max-height : 850px){
    .c2c_confirmDetails {
        height: calc( 60vh - 30px);
    }
}
@media ( max-height : 800px){
    .c2c_confirmDetails {
        height: calc( 60vh - 65px);
    }
}
@media ( max-height : 700px){
    .c2c_confirmDetails {
        height: calc( 60vh - 105px);
    }
}
@media ( max-height : 600px){
    .c2c_confirmDetails {
        height: calc( 60vh - 135px);
    }
}
@media ( max-height : 500px){
    .c2c_confirmDetails {
        height: calc( 60vh - 176px);
    }
}
@media ( max-height : 400px){
    .c2c_confirmDetails {
        height: calc( 60vh - 185px);
    }
}
@media ( max-width : 690px){
    @media ( max-height : 900px){
        .c2c_confirmDetails {
            height: calc( 60vh - 89px);//102px);
        }
    }
    @media ( max-height : 800px){
        .c2c_confirmDetails {
            height: calc( 60vh - 127px);//145px);
        }
    }
    @media ( max-height : 700px){
        .c2c_confirmDetails {
            height: calc( 60vh - 168px);//187px);
        }
    }
    @media ( max-height : 600px){
        .c2c_confirmDetails {
            height: calc( 60vh - 207px);//228px);
        }
    }
    @media ( max-height : 500px){
        .c2c_confirmDetails {
            height: calc( 60vh - 190px);//176px);
        }
    }
    @media ( max-height : 400px){
        .c2c_confirmDetails {
            height: calc( 60vh - 182px);//211px);
        }
    }
}

