.c2c_termscondition{
    // height: 60vh;
    height: 63vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    padding:10px 10px 10px 0px;
    color: #7b7b7b!important;
    width:"100%";
   

    .title{
        display: flex;
        justify-content: center;
        font-size: 24px;
        margin-bottom: 10px;
        margin-top: 20px;
        font-weight: 700;
    }

    .subTitle{
        display: flex;
        justify-content: center;
        font-size: 14px;
        margin-bottom: 0px;
        margin-top: 0px;
        font-weight: 700;
    }

    .annex{
        font-size: 14px;
        margin-bottom: 0px;
        margin-top: 0px;
        font-weight: 700;
    }

    .listTypeDot{
        list-style-type: disc;
    }

    .ordered_list{
        padding-left: 20px;

        .list-number {
            list-style-type: decimal;
            padding-top: 12px;
            word-break:break-all;
            line-height: 20px;

            .lowerabc{
                list-style-type: lower-alpha;
                padding-top: 12px;
                word-break:break-all;
            }
        }
    }
}

.footer{
    border-top: 1px solid #7b7b7b;
    background-color: white;
    display: flex;

    .button{
        margin-top: 10px;
        margin-bottom: 10px;
        color:#fff;
        background:#1acbb1;
        cursor: pointer;
        font-size: 18px!important;
        font-family: c2c-roboto-regular!important;
        height: 40px!important;
        width: 106px!important;
        padding: 6px 12px!important;
        border-radius: 6px!important;
        border: none;
        font-weight: 600!important;
    }

    .checked{
        align-items: center;
        display: flex;

        .checkboxCaption{
           font-size: 14px !important;
           overflow: "auto";
           text-overflow: "ellipsis";
           color: #7b7b7b!important;
           font-family: c2c-roboto-regular!important;
        }

        .ant-checkbox-inner {
            width: 25px;
            height: 25px;
        }
       
    }
}
@media ( max-width : 768px){
    .c2c_termscondition {
        height: calc( 60vh - 75px);
    }
}
@media ( max-height : 850px){
    .c2c_termscondition {
        height: calc( 60vh - 40px);
    }
}
@media ( max-height : 800px){
    .c2c_termscondition {
        height: calc( 60vh - 75px);
    }
}
@media ( max-height : 700px){
    .c2c_termscondition {
        height: calc( 60vh - 115px);
    }
}
@media ( max-height : 600px){
    .c2c_termscondition {
        height: calc( 60vh - 145px);
    }
}
@media ( max-height : 500px){
    .c2c_termscondition {
        height: calc( 60vh - 186px);
    }
}
@media ( max-height : 400px){
    .c2c_termscondition {
        height: calc( 60vh - 195px);
    }
}
@media ( max-width : 690px){
    :where(.css-dev-only-do-not-override-nnuwmp).ant-checkbox+span{
        line-height: 16px;
    }
    @media ( max-height : 900px){
        .c2c_termscondition {
            height: calc( 60vh - 94px);
        }
    }
    @media ( max-height : 800px){
        .c2c_termscondition {
            height: calc( 60vh - 145px);
        }
    }
    @media ( max-height : 700px){
        .c2c_termscondition {
            height: calc( 60vh - 173px);
        }
    }
    @media ( max-height : 600px){
        .c2c_termscondition {
            height: calc( 60vh - 213px);
        }
    }
    @media ( max-height : 500px){
        .c2c_termscondition {
            height: calc( 60vh - 169px);
        }
    }
    @media ( max-height : 400px){
        .c2c_termscondition {
            height: calc( 60vh - 182px);//211px);
        }
    }
}